<template>
    <v-container fluid>
        <v-row v-if="loading || !options.offerTypes">
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                <v-skeleton-loader
                    class="mx-auto"
                    max-width="100%"
                    type="card"
                ></v-skeleton-loader>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2" class="d-none d-sm-block">
                <v-skeleton-loader
                    class="mx-auto"
                    max-width="100%"
                    type="card"
                ></v-skeleton-loader>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2" class="d-none d-md-block">
                <v-skeleton-loader
                    class="mx-auto"
                    max-width="100%"
                    type="card"
                ></v-skeleton-loader>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2" class="d-none d-lg-block">
                <v-skeleton-loader
                    class="mx-auto"
                    max-width="100%"
                    type="card"
                ></v-skeleton-loader>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2" class="d-none d-xl-block">
                <v-skeleton-loader
                    class="mx-auto"
                    max-width="100%"
                    type="card"
                ></v-skeleton-loader>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2" class="d-none d-xl-block">
                <v-skeleton-loader
                    class="mx-auto"
                    max-width="100%"
                    type="card"
                ></v-skeleton-loader>
            </v-col>
        </v-row>
        <v-row v-else>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2" v-for="design in designs.data" :key="design.id">
                <v-card>
                    <v-container fluid class="pt-0">
                        <v-row justify="space-between">
                            <v-col cols="12" style="position: relative;" class="pa-0" >
                                <v-skeleton-loader
                                    v-if="!design.imageUrl"
                                    width="100%"
                                    boilerplate
                                    type="image"
                                ></v-skeleton-loader>
                                <v-img
                                    v-else
                                    max-width="100%"
                                    :aspect-ratio="400/623"
                                    position="top"
                                    :src="design.imageUrl"
                                >
                                    <template v-slot:placeholder>
                                        <v-row
                                            class="fill-height ma-0"
                                            align="center"
                                            justify="center"
                                        >
                                            <v-progress-circular indeterminate color="grey"></v-progress-circular>
                                        </v-row>
                                    </template>
                                </v-img>
                                <v-chip label color="primary" style="position: absolute; top: 0; left: 0;">id: {{ design.id }}</v-chip>
                            </v-col>

                            <v-col
                                cols="12"
                                class="pb-0"
                            >
                                <v-row
                                    class="flex-row"
                                    justify="space-between"
                                >
                                    <v-col>
                                        {{ design.label }}
                                    </v-col>
                                    <v-col class="text-center">
                                        <v-row>
                                            <v-col cols="4" class="pa-0" v-for="(icon, type) in design.offerTypesIcons" :key="icon">
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon v-text="icon" color="primary" v-on="on"></v-icon>
                                                    </template>
                                                    <span>{{ options.offerTypesKeyed[type] }}</span>
                                                </v-tooltip>

                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                                <v-row v-if="design.datas">
                                    <v-col>
                                        {{ trans('fields.common.languages') }}:
                                    </v-col>
                                    <v-col>
                                        <template v-for="data in design.datas">
                                            <v-tooltip
                                                top
                                               :key="data.language.id"
                                                v-if="$options.components['Flag' + data.language.locale[0].toUpperCase()+data.language.locale.slice(1).toLowerCase()]"
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <span v-on="on">
                                                    <component
                                                        class="d-inline-block"
                                                        :is="'flag-' + data.language.locale"
                                                    ></component>
                                                    </span>
                                                </template>
                                                <span>{{ options.languagesKeyed[data.language.id] }}</span>
                                            </v-tooltip>

                                        </template>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col class="text-center">
                                        <v-menu
                                                top
                                                close-on-content-click
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn
                                                        color="primary"
                                                        outlined
                                                        v-bind="attrs"
                                                        v-on="on"
                                                >{{ trans('buttons.landingDesign.livePreview') }}</v-btn>
                                            </template>
                                            <v-list>
                                                <v-list-item
                                                        v-for="data in design.datas"
                                                        :key="data.id"
                                                        :href="route('user.landingDesigns.preview', design.id, data.language.id)"
                                                        target="_blank"
                                                >
                                                    <v-list-item-icon >
                                                        <component
                                                                v-if="$options.components['Flag' + data.language.locale[0].toUpperCase()+data.language.locale.slice(1).toLowerCase()]"
                                                                class="d-inline-block"
                                                                :is="'flag-' + data.language.locale"
                                                        ></component>
                                                    </v-list-item-icon>
                                                    <v-list-item-title>{{ options.languagesKeyed[data.language_id] }}</v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-pagination
                    v-model="paginationParams.page"
                    :length="pagination.pageStop"
                    :page="pagination.page"
                    :total-visible="12"
                    :disabled="loading"
                    @input="changePage"
                ></v-pagination>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">

            </v-col>
        </v-row>
    </v-container>


</template>

<script>
    import tableWithPagination from "@/mixins/tableWithPagination";
    import FlagEn from "@/components/commonComponents/flags/FlagEn";
    import FlagRu from "@/components/commonComponents/flags/FlagRu";
    import FlagUk from "@/components/commonComponents/flags/FlagUk";
    import FlagKk from "@/components/commonComponents/flags/FlagKk";
    import FlagPl from "@/components/commonComponents/flags/FlagPl";
    import FlagLv from "@/components/commonComponents/flags/FlagLv";
    import FlagEs from "@/components/commonComponents/flags/FlagEs";
    import FlagVi from "@/components/commonComponents/flags/FlagVi";

    export default {
        name: "LandingDesignsTable",
        components: {
            FlagEn,
            FlagRu,
            FlagUk,
            FlagKk,
            FlagPl,
            FlagLv,
            FlagEs,
            FlagVi,
        },
        mixins: [tableWithPagination],
        data() {
            return {
                designs: {},
                headers: [
                    { text: "id", sortable: false, value: "id" },
                    { text: this.trans("fields.common.label"), value: "label", sortable: false },
                    { text: this.trans("fields.common.offerTypes"), value: "offerTypes", sortable: false },
                ],
                paginationParams: {
                    page: 1,
                    per_page: 12,
                },
                url: this.route('user.landingDesigns.index'),
            }
        },
        created() {
            this.editedItem = Object.assign({}, this.defaultItem);
            this.loadOptions({offerTypes: true, languages: true});
        },
        watch: {
            'paginationParams.per_page'(){
                this.changeItemsPerPage();
            },
        },
        methods: {
        },
        computed: {
            paginationItems: {
                get(){
                    return this.designs;
                },
                set(v){
                    this.designs = v;
                }
            },
        }
    }
</script>

<style scoped>
    .small-caption {
        font-size: 9px;
        margin-left: -6px;
    }
</style>
